const ar = {
  home: 'الرئيسية',
  najizPortal: 'منصة ناجز',
  judicalEServices: 'المنصة الموحدة للخدمات العدلية الإلكترونية',
  enter: 'الدخول',
  noServicesFoundPleaseTryAgain: 'لا توجد خدمات.',
  new: 'جديد',
  modified: 'معدلة',
  redirectToService: 'الانتقال الى خدمة-',
  scrollMore: 'مرر للمزيد',
  businessAccountActivationRequirements: 'أبرز متطلبات تفعيل حساب أعمال',
  accountValidityPeriod: 'مدة صلاحية الحساب',
  fromActivationDate: 'من تاريخ التفعيل',
  accountCreationFees: 'رسوم إنشاء الحساب',
  activateFirstOrganizationAccountText: 'تفعيل الحساب الأول للمنشاة',
  activateFirstOrganizationAccountDescription: 'وسيتم احتساب رسوم للحسابات الإضافية .',
  free: 'مجانًا',
  sar: 'ريال سعودي',
  year: 'سنة',

  verificationServices: {
    title: 'خدمات التحقق',
    description: 'تمكنك هذه الخدمة من التحقق من الوثائق الصادرة من وزارة العدل إلكترونياً',
    userNeed: 'أريد التحقق من',
    browseVerificationServices: 'عرض كل الخدمات',
  },

  appInsights: {
    verificationServicesArName: 'خدمات التحقق',
  },

  serviceUsage: 'استخدام الخدمة',
  mostUsed: 'الأكثر استخداماً',
  serviceExplaination: 'شرح الخدمة',

  services: {
    title: 'الخدمات الإلكترونية',
    explaination: 'شرح الخدمة',
    browseAllServices: 'عرض كل الخدمات',
    searchInServices: 'البحث في الخدمات',
    prominentServices: 'أبرز الخدمات الإلكترونية',
  },

  licenseType: 'نوع الترخيص',
  chooseLicenseType: 'اختر نوع الترخيص',
  iWantToPreview: 'أريد استعراض دليل ',
  najizData: 'ناجز بيانات',
  last7Days: 'اخر 7 أيام',
  najizDataDescription: 'الحل الأمثل لطلب  البيانات الموثوقة وخلق فرص جديدة لأعمال',
  najizDataLogin: 'دخول لناجز بيانات',
  najizInNumbers: 'ناجز بالأرقام',

  faqs: {
    title: 'الأسئلة المتكررة',
    commonQuestions: 'الأسئلة الشائعة',
    searchPlaceholder: 'ابحث هنا، مثلاً: كيفية إنشاء صحيفة دعوى',
    howCanWeHelpYou: 'كيف يمكننا مساعدتك؟',
    browseAllQuestions: 'تصفح جميع الأسئلة',
    noQuestionsAvailable: 'لا توجد أسئلة.',
    shareQuestion: 'مشاركة السؤال',
    questionLinkCopiedSuccessfully: 'تم نسخ رابط السؤال بنجاح',
    questionDetails: 'تفاصيل السؤال',
    questionDetailsNotAvailable: 'بيانات السؤال غير متاحة',
  },

  najizPartners: 'شركاء ناجز',

  contactUs: {
    title: 'تواصل معنا',
    forMoreContacts: 'لمزيد من قنوات التواصل',
  },
  mobileApplication: {
    title: 'تطبيق ناجز',
    downloadAvailability: 'يمكنكم تحميل تطبيق ناجز',
    devicesText: 'للأجهزة الذكية التي تعمل بنظامي أندرويد وأيفون',
  },

  useTheService: 'استخدم الخدمة',
  startTheService: 'بدء الخدمة',
  serviceDescription: 'وصف الخدمة',
  serviceRequirements: 'متطلبات الخدمة',
  stepsToApplyForTheService: 'خطوات التقديم على الخدمة',
  submitNewRequest: 'تقديم طلب جديد',
  followUpOnPreviousRequest: 'متابعة طلب سابق',
  authenticationOfCompletedRequest: 'مصادقة طلب مكتمل',
  serviceVideoExplaination: 'فيديو شرح الخدمة',
  youShouldKnow: 'يجب أن تعرف',

  fetchingData: 'جاري تحميل البيانات ...',
  searchResult: 'نتيجة البحث',
  searchResults: 'نتائج البحث ({searchText})',
  browseService: 'تصفح الخدمة ',
  noSearchResultsFound: 'لا يوجد نتائج تطابق البيانات المدخلة',
  noSearchResultsMessage: 'الرجاء التأكد من البيانات، والمحاولة مجدداً.',

  login: 'تسجيل الدخول',
  activateBusinessAccount: 'تفعيل حساب أعمال',
  individuals: 'أفراد',
  businesses: 'أعمال',
  government: 'حكومة',
  lawyers: 'محامين',
  individualsLoginMsg: 'تسجيل الدخول للأفراد من مواطنين و مقيمين و زوار',
  businessesLoginMsg: 'تسجيل الدخول لملاك وممثلي الشركات والمؤسسات و منشآت النفع العام',
  governmentLoginMsg: 'تسجيل الدخول لممثلي الجهات الحكومية',
  lawyersLoginMsg: 'تسجيل الدخول للمحامين المرخصين من قبل وزارة العدل',
  forMoreDetails: 'للمزيد من التفاصيل',
  requiredDocuments: 'المستندات المطلوبة',
  serviceExecutionTime: 'مدة تنفيذ الخدمة',
  instant: 'فورية',
  serviceExecutionTimeDescription: 'من {from} - إلى {to}',
  serviceProviderChannels: 'قنوات تقديم الخدمة',
  beneficiaries: 'الفئة المستهدفة',
  serviceFees: 'رسوم الخدمة ',
  commonFaqs: 'الأسئلة الشائعة',
  checkFaqs: 'الاطلاع على الأسئلة الشائعة',
  clickHere: 'اضغط هنا',
  signalSupport: 'دعم الأشارة',
  signalCustomerSupport: 'الدعم الفني للغة الاشارة',
  allServices: 'جميع الخدمات',
  downloadApp: 'تحميل التطبيق',
  usageGuide: 'دليل الأستخدام',
  downloadUsageGuide: 'تنزيل دليل الإستخدام',
  theDescription: 'الوصف',
  theRequirements: 'المتطلبات',
  theSteps: 'الخطوات',
  share: 'مشاركة',
  serviceLinkCopiedSuccessfully: 'تم نسخ رابط الخدمة بنجاح',
  loginToCreateAccount: 'تسجيل دخول لتفعيل الحساب',

  sessionsAttendence: 'حضور الجلسات',
  ePayment: 'الدفع الإلكتروني',
  executionRequestActions: 'الإجراءات على طلب التنفيذ',
  virtualCourtExecutionRequest: 'طلب تنفيذ المحكمة الافتراضية',
  nowFromApp: 'الآن من التطبيق',
  downloadAppNow: 'حمل تطبيق ناجز الآن',

  serverErrors: {
    USER_NOT_FOUND: 'المستخدم غير موجود الرجاء المحاولة مرة أخرى',
    INVALID_DATA: 'حدث خطأ الرجاء المحاولة مرة أخرى',
    SERVER_ERROR: 'حدث خطأ في النظام الرجاء المحاولة لاحقاً',
  },
};

export default ar;
