import { RouteConfig } from 'vue-router';
import Breadcrumbs from './breadcrumbs';

const Home = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/Home');
const FAQs = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "FAQs" */ '@/views/FAQs/faqs');
const Services = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "E-services" */ '@/views/Services/services');
const ServiceDetails = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ServiceDetails" */ '@/views/ServiceDetails/serviceDetails');
const SmartSearchResult = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ServiceDetails" */ '@/views/SmartSearchResult/smartSearchResult');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      eventName: 'landing',
      eventNameAr: 'صفحة الهبوط',
      eventNameEn: 'landing',
    },
  },
  {
    path: '/e-services',
    name: 'Services',
    component: Services,
    meta: {
      breadcrumb: Breadcrumbs.services,
      eventName: 'e-services',
      eventNameAr: 'الخدمات الالكترونية',
      eventNameEn: 'e-services',
    },
  },
  {
    path: '/e-services/:serviceId',
    name: 'ServiceDetails',
    component: ServiceDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.serviceDetails,
      eventName: 'ServiceDetails',
      eventNameAr: 'تفاصيل الخدمات',
      eventNameEn: 'ServiceDetails',

    },
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.faqs,
      eventName: 'faqs',
      eventNameAr: 'الأسئلة الشائعة',
      eventNameEn: 'faqs',

    },
  },
  {
    path: '/search/:searchText',
    name: 'smart-search-result',
    component: SmartSearchResult,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.searchResult,
      eventName: 'search-results',
      eventNameAr: 'نتائج البحث',
      eventNameEn: 'search results',

    },
  },
  // ? : for old routes
  {
    path: '/services',
    name: 'old-services',
    component: Services,
    meta: {
      breadcrumb: Breadcrumbs.services,
      eventName: 'old-services',
      eventNameAr: 'الخدمات',
      eventNameEn: 'old services',

    },
  },
  {
    path: '/service/:serviceId',
    name: 'old-ServiceDetails',
    component: ServiceDetails,
    meta: {
      highlighted: true,
      breadcrumb: Breadcrumbs.serviceDetails,
      eventName: 'ServiceDetails',
      eventNameAr: 'تفاصيل الخدمات',
      eventNameEn: 'Service Details',

    },
  },
  {
    path: '/faq',
    name: 'old-FAQs',
    component: FAQs,
    meta: {
      breadcrumb: Breadcrumbs.faqs,
      eventName: 'faq',
      eventNameAr: 'الأسئلة الشائعة',
      eventNameEn: 'faq',

    },
  },
] as RouteConfig[];

export default routes;
